import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import "./style.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"
import { handleLogin, setServices } from "@services/auth"

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      username: ``,
      password: ``,
    }
  }

  changeHandler = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
      error: false,
    })
  }

  submitHandler = (ev) => {
    ev.preventDefault()
    let { username, password } = this.state
    if (username && password) {
      const data = { username, password }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "http://127.0.0.1:9210/",
          // "Access-Control-Allow-Credentials": "true",
        },
        data: JSON.stringify(data),
        url: `${process.env.API_URL}/login`,
      }
      axios(options)
        .then((res) => {
          handleLogin(res.data)
          this.getServices()
        })
        .catch((err) => {
          console.log(err)
          this.setState({ error: true })
        })
    }
  }

  getServices() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/services`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        setServices(res.data)
        // debugger
        console.log(res.data[0].name)
        if (res.data[0].name === "Timecard") {
          navigate("/services/timecard")
        } else {
          navigate("/services/two-way")
        }
      })
      .catch((err) => {
        this.setState({ error: true })
      })
  }

  render() {
    return (
      <section id="login-form-section" className="container">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="form"
          onSubmit={this.submitHandler.bind(this)}
        >
          <label htmlFor="loginEmail">Login Username or Email</label>
          <input
            type="text"
            className="w-input"
            maxLength="256"
            name="username"
            data-name="loginEmail"
            placeholder="Email"
            id="loginEmail"
            value={this.state.username}
            onChange={this.changeHandler.bind(this)}
          />
          <label htmlFor="loginPassword">Login Password</label>
          <input
            type="password"
            className="w-input"
            maxLength="256"
            name="password"
            data-name="loginPassword"
            placeholder="Password"
            id="loginPassword"
            required=""
            value={this.state.password}
            onChange={this.changeHandler.bind(this)}
          />
          <Button
            variant="primary"
            type="submit"
            id="login-button"
            data-wait="Please wait..."
            className="w-button"
            disabled={!(this.state.username && this.state.password)}
          >
            Submit
          </Button>
          {this.state.error && (
            <p
              className="error-text"
              style={{
                padding: `0 25%`,
                textAlign: `center`,
                marginBottom: 0,
              }}
            >
              Error, please check your password and try again.
            </p>
          )}
          <p style={{ textAlign: `center`, marginTop: `1rem` }}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </form>
      </section>
    )
  }
}

export default LoginForm
