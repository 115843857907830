import React, { Component } from "react"
import ChecklistForm from "@components/_molecules/form_checklist"
import axios from "axios"

class Checklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lists: [],
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
    }
  }

  componentDidMount() {
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then(res => {
        let { session_id } = res.data
        this.setState({ session_id })
        this.getList()
      })
      .catch(err => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getList() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then(res => {
        let { data } = res
        console.log(res)
        this.setState({ lists: data })
      })
      .catch(err => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  render() {
    return (
      <div className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <div style={{ width: `100%` }}>
            <ChecklistForm style={{ flex: 1 }} />
          </div>
        </div>
      </div>
    )
  }
}

export default Checklist
