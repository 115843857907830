import React from "react"
import { Router } from "@reach/router"
import Layout from "@components/layout"
import PrivateRoute from "@components/privateRoute"
import Timecard from "@components/_services/timecard"
import TwoWay from "@components/_services/two-way"
import NewList from "@components/_services/newlist.js"
import Checklist from "@components/_services/checklist"
import ChecklistTasks from "@components/_services/tasks"
import ChecklistSummary from "@components/_services/summary.js"
import Login from "@components/_services/login"
import Logout from "@components/_services/logout"

const Services = () => (
  <Layout>
    <Router>
      <PrivateRoute default path="/services/" component={Timecard} />
      <PrivateRoute path="/services/timecard" component={Timecard} />
      <PrivateRoute path="/services/two-way" component={TwoWay} />
      <PrivateRoute path="/services/newlist" component={NewList} />
      <PrivateRoute path="/services/checklist" component={Checklist} />
      <PrivateRoute path="/services/tasks" component={ChecklistTasks} />
      <PrivateRoute path="/services/summary" component={ChecklistSummary} />
      <PrivateRoute path="/validate" />
      <Login path="/services/login" />
      <Logout path="/services/logout" component={Logout} />
    </Router>
  </Layout>
)

export default Services
