import React, { Component } from "react"

class ChecklistItemRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      status: "",
    }
  }

  getModal = (e, item) => {
    this.props.showExisting(e, item)
  }

  getStatus(status) {
    switch (status) {
      case "U":
        return "Upcoming"
      case "O":
        return "Overdue"
      case "C":
        return "Complete"
      default:
        return null
    }
  }

  render() {
    const data = this.props.fields.fields
    const id = this.props.fields.pk
    const status = this.getStatus(data.status)
    const row = {
      height: "90px",
      borderBottom: "1px solid #35393B",
      cursor: "pointer",
    }
    return (
      <tr
        id={id}
        style={row}
        onClick={(e, item) => {
          this.getModal(e, this.props.fields)
        }}
      >
        <td>{data.date_due}</td>
        <td>{data.title}</td>
        <td>
          {status}
          <span
            className="invert"
            role="img"
            aria-label="unchecked box"
            alt="unchecked box"
          >
            🔳
          </span>
        </td>
      </tr>
    )
  }
}

export default ChecklistItemRow
