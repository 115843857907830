import React, { Component } from "react"

import Button from "react-bootstrap/Button"
import axios from "axios"
import Dropzone from "react-dropzone"

class UpdateTaskDetailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pk: this.props.pk,
      item_status: this.props.item_status,
      type: this.props.item_type,
      title: this.props.title,
      date_due: this.props.date_due,
      description: this.props.description,
      session_id: this.props.session_id,
      public_notes: this.props.public_notes,
      private_notes: this.props.private_notes,
      requiredFiles: [],
      selectedFiles: [],
      availableDocs: [],
    }
  }

  componentDidMount() {
    this.getDocs()
  }

  onClose = (e) => {
    this.props.hideModal(e)
  }
  submitTask = (ev) => {
    ev.preventDefault()
    if (this.state.selectedFiles.length) {
      window.onbeforeunload = function () {
        return true
      }
    } else {
      window.onbeforeunload = null
    }
    let {
      type,
      title,
      date_due,
      description,
      pk,
      item_status,
      session_id,
      public_notes,
      private_notes,
    } = this.state
    let list = this.props.listId
    const data = {
      list_id: list,
      item_type: type,
      item_title: title,
      item_due: date_due,
      item_desc: description,
      item_id: [pk],
      item_status,
      public_notes,
      private_notes,
      session_id,
    }
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
        // "Access-Control-Allow-Origin": "http://127.0.0.1:9210/",
        // "Access-Control-Allow-Credentials": "true",
      },
      params: data,
      url: `${process.env.API_URL}/twcl/items`,
    }
    axios(options)
      .then((res) => {
        if (typeof window !== `undefined`)
          window.location = window.location.href
      })
      .catch((err) => {
        this.setState({ error: true })
      })
  }
  changeHandler = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    })
  }

  getDocs() {
    let { session_id } = this.state
    let item_id = this.props.pk
    let data = { item_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/files`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        this.setState({ availableDocs: data })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  uploadFile = (ev) => {
    ev.preventDefault()
    let { selectedFiles, session_id, pk } = this.state
    let formData = new FormData()
    formData.append(
      `metadata`,
      `{"session_id": "${session_id}","item_id": "${pk}"}`
    )
    selectedFiles.map((selectedFile) => {
      formData.append(selectedFile.name, selectedFile, selectedFile.name)
      return null
    })
    const uploadOptions = {
      method: "PUT",
      url: `${process.env.API_URL}/twcl/files`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data: formData,
    }
    axios(uploadOptions)
      .then((res) => {
        let { data } = res
        this.setState({ availableDocs: data, selectedFiles: [] })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  fileSelectedHandler = (ev) => {
    let { selectedFiles } = this.state
    selectedFiles[0] = ev[0]
    let error = false
    this.setState({ selectedFiles, error })
  }

  getDocHandler = (ev) => {
    ev.preventDefault()
    let { session_id } = this.state
    let file_id = ev.target.getAttribute("doc")
    let file_name = ev.target.getAttribute("name")
    let data = { session_id, file_id }
    const uploadOptions = {
      method: "GET",
      responseType: "arraybuffer",
      url: `${process.env.API_URL}/twcl/files`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      params: data,
    }
    axios(uploadOptions)
      .then((res) => {
        let contentType = res.headers["content-type"]
        let blob = new Blob([res.data], { type: contentType })
        let fileContent = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = fileContent
        link.setAttribute("download", `${file_name}`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  render() {
    const button = {
      margin: "22px",
      width: "auto",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const header = {
      borderBottom: "1px solid #35393B",
      paddingTop: "18px",
      paddingBottom: "18px",
      paddingLeft: "50px",
    }
    const dropdown = {
      width: "807px",
      height: "60px",
      padding: "19px",
    }
    const input = {
      width: "627px",
      height: "60px",
      padding: "19px",
      display: "inline-block",
    }
    let modalDisplay = this.props.show ? `block` : `none`
    let { selectedFiles, availableDocs } = this.state
    let showUpload = selectedFiles.length > 0
    let { description, date_due } = this.state

    return (
      <div className="modal" style={{ display: modalDisplay }}>
        <section className="modal-main">
          <div style={header}>
            {this.props.isNew ? `New Task` : `Edit Task`}:{" "}
            <span style={{ fontWeight: "bold" }}>
              {this.props.taskListName}
            </span>
          </div>
          <div className="container" style={{ padding: "42px" }}>
            <label htmlFor="taskType">Task Type</label>
            <br />
            <select
              id="taskType"
              name="type"
              style={dropdown}
              onChange={this.changeHandler.bind(this)}
              onBlur={this.changeHandler.bind(this)}
              defaultValue={this.props.item_type}
            >
              <option value="Y">Acknowledgement</option>
              <option value="F">Upload Request</option>
            </select>
            <br />
            <label htmlFor="title" style={{ marginTop: "40px" }}>
              Title
            </label>
            <br />
            <input
              type="text"
              id="title"
              name="description"
              style={input}
              onSubmit={this.submitTask.bind(this)}
              onChange={this.changeHandler.bind(this)}
              value={this.state.description}
            />
            <br />
            <label htmlFor="dueDate" style={{ marginTop: "40px" }}>
              Due Date
            </label>
            <br />
            <input
              type="date"
              id="listName"
              name="date_due"
              style={input}
              onChange={this.changeHandler.bind(this)}
              value={this.state.date_due}
            />
            <br />
            <label htmlFor="public_notes" style={{ marginTop: "40px" }}>
              Public Notes
            </label>
            <br />
            <textarea
              onChange={this.changeHandler}
              name="public_notes"
              id="public_notes"
              cols="79"
              rows="10"
              defaultValue={this.state.public_notes}
            ></textarea>
            <br />
            <label htmlFor="private_notes" style={{ marginTop: "40px" }}>
              Private Notes
            </label>
            <br />
            <textarea
              onChange={this.changeHandler}
              name="private_notes"
              id="private_notes"
              cols="79"
              rows="10"
              defaultValue={this.state.private_notes}
            ></textarea>
            <br />
            <label
              style={{ display: `block`, marginTop: `32px` }}
              htmlFor="status-dropdown"
            >
              Status
            </label>
            <select
              id="-dropdown"
              name="item_status"
              style={dropdown}
              defaultValue={this.props.status}
              onChange={this.changeHandler.bind(this)}
              onBlur={this.changeHandler.bind(this)}
              value={this.state.item_status}
            >
              <option value="U">To Be Completed</option>
              <option value="C">Complete</option>
              <option value="A">Archive</option>
              <option value="NA">Not Applicable</option>
            </select>
            {this.state.type === "F" && (
              <div className="file-drop-row">
                <label
                  htmlFor="document"
                  style={{ marginTop: "40px", width: `100%` }}
                >
                  Document
                </label>
                <Dropzone
                  onDropAccepted={(files) => this.fileSelectedHandler(files)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="dropzone"
                      {...getRootProps()}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <input {...getInputProps()} />
                      <p style={{ opacity: selectedFiles[0] ? 1 : 0.5 }}>
                        {selectedFiles[0]
                          ? selectedFiles[0].name
                          : `Drop file here`}
                      </p>
                      <Button
                        id="filesubmit"
                        name="filesubmit"
                        style={{ width: `120px` }}
                      >
                        Choose
                      </Button>
                    </div>
                  )}
                </Dropzone>
                {showUpload && (
                  <Button
                    onClick={this.uploadFile.bind(this)}
                    style={{ width: `120px` }}
                  >
                    Upload
                  </Button>
                )}
              </div>
            )}
            {availableDocs && (
              <ul className="available-files">
                {availableDocs.map((doc, index) => (
                  <li key={index}>
                    <a
                      href={`/doc-${doc}`}
                      doc={doc.file_id}
                      name={doc.file_name}
                      onClick={this.getDocHandler.bind(this)}
                    >
                      {doc.file_name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <div
              style={{
                marginTop: `2rem`,
                width: `100%`,
                display: `flex`,
                justifyContent: `flex-start`,
              }}
            >
              <Button
                style={button}
                onClick={(e) => {
                  this.onClose(e)
                }}
              >
                Cancel
              </Button>
              <Button
                style={button}
                disabled={!(!!date_due && !!description)}
                onClick={(e) => {
                  this.submitTask(e)
                }}
              >
                Save
              </Button>
              {this.state.selectedFiles.length > 0 && (
                <p className="error-text" style={{ lineHeight: `64px` }}>
                  Warning: You have chosen a file that has not yet been uploaded
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default UpdateTaskDetailModal
