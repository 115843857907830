import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import axios from "axios"

class ChecklistIndexRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      status: "",
    }
  }

  getStatus(status) {
    switch (status) {
      case "I":
        return "In Progress"
      case "C":
        return "Complete"
      default:
        return null
    }
  }

  deleteList() {
    if (typeof window !== `undefined`) {
      if (window.confirm(`Deleting an item cannot be undone, continue?`)) {
        const checklistParams = {
          method: "PUT",
          url: `${process.env.API_URL}/twcl/list`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.bp_id}`,
          },
          params: {
            session_id: "session",
            list_id: this.props.list.pk,
            status: "D",
          },
        }
        axios(checklistParams)
          .then((res) => {
            navigate("/services/two-way/")
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  render() {
    const data = this.props.list.fields
    const id = this.props.list.pk
    const status = this.getStatus(data.status)
    const row = {
      height: "90px",
      borderBottom: "1px solid #35393B",
    }
    return (
      <tr id={id} style={row}>
        <td className="list-due-date">{data.date_modified}</td>
        <td>
          <Link to={`/services/tasks?pk=${id}`}>{data.title}</Link>
        </td>
        <td>
          {status}
          <button
            onClick={this.deleteList.bind(this)}
            onKeyUp={this.deleteList.bind(this)}
            style={{
              marginLeft: "15px",
              cursor: "pointer",
              background: `none`,
              display: `inline`,
              float: `right`,
              width: `auto`,
              padding: 0,
            }}
          >
            <span role="img" aria-label="Trash">
              🗑️
            </span>
          </button>
        </td>
      </tr>
    )
  }
}

export default ChecklistIndexRow
