import React, { Component } from "react"
import { navigate } from "gatsby"
import "./services.scss"
import Sidebar from "@components/_services/sidebar"
import axios from "axios"
import Button from "react-bootstrap/Button"

class ChecklistSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      sessionId: null,
      pk: ``,
      items: [],
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
      session_id:
        typeof window !== `undefined` ? window.sessionStorage.bp_id : ``,
      lists: [],
      thisList: [],
      public_notes: ``,
      private_notes: ``,
      editing: false,
      edited: false,
      list_company: ``,
      date_estimated: ``,
      list_contact: ``,
      contact_email: ``,
      contact_phone: ``,
    }
  }

  componentDidMount() {
    let urlParams =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : ``
    this.setState({ pk: urlParams.get("pk") })
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
  }

  componentWillReceiveProps(nextProps) {
    this.getList()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { session_id } = res.data
        this.setState({ session_id })
        this.getList()
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getList() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let urlParams =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : ``
    let pk = urlParams.get("pk")
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        let thisList = data.filter((list) => list.pk.toString() === pk)
        this.setState({ lists: data, thisList })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  changeHandler = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    })
  }

  markCompleteHandler(ev) {
    ev.preventDefault()
    if (typeof window !== `undefined`) {
      let urlParams =
        typeof window !== `undefined`
          ? new URLSearchParams(window.location.search)
          : ``
      let pk = urlParams.get("pk")
      if (window.confirm(`Marking complete cannot be undone, continue?`)) {
        const checklistParams = {
          method: "PUT",
          url: `${process.env.API_URL}/twcl/list`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.bp_id}`,
          },
          params: {
            session_id: "session",
            list_id: pk,
            list_status: "C",
          },
        }
        axios(checklistParams)
          .then((res) => {
            navigate("/services/two-way/")
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  saveNotesHandler(ev) {
    ev.preventDefault()
    if (typeof window !== `undefined`) {
      let urlParams =
        typeof window !== `undefined`
          ? new URLSearchParams(window.location.search)
          : ``
      let pk = urlParams.get("pk")
      const checklistParams = {
        method: "PUT",
        url: `${process.env.API_URL}/twcl/list`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${sessionStorage.bp_id}`,
        },
        params: {
          session_id: "session",
          list_id: pk,
          public_notes: this.state.public_notes,
          private_notes: this.state.private_notes,
        },
      }
      axios(checklistParams)
        .then((res) => {
          window.location = window.location.href
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  editHandler(ev) {
    ev.preventDefault()
    this.setState({ editing: !this.state.editing })
  }

  render() {
    const table = {
      marginTop: "0px",
      borderTop: "none",
    }
    const cell = {
      borderBottom: "1px solid #35393B",
      textAlign: "left",
      height: "80px",
    }
    const button = {
      margin: "10px",
      width: "121px",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const input = {
      width: "360px",
      marginLeft: "120px",
      textAlign: "right",
    }
    let { lists, thisList } = this.state
    return (
      <div id="two-way" className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <>
            <Sidebar lists={this.state.lists} />
          </>
          <div style={{ width: `100%` }}>
            <div className="list-header">
              Summary - {lists.length && thisList[0].fields.title}
            </div>
            <div className="container" style={{ padding: "60px" }}>
              {lists.length && (
                <table style={table}>
                  <tbody>
                    <tr>
                      <td style={cell}>Company Name</td>
                      <td style={cell}>
                        {!this.state.editing ? (
                          <span
                            className="data-cell"
                            style={{ fontWeight: "bold", float: "left" }}
                          >
                            {thisList[0].fields.list_company}
                          </span>
                        ) : (
                          <input
                            name="list_company"
                            style={input}
                            onChange={this.changeHandler}
                            defaultValue={thisList[0].fields.list_company}
                          ></input>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={cell}>First Check Date</td>
                      <td style={cell}>
                        {!this.state.editing ? (
                          <span
                            className="data-cell"
                            style={{ fontWeight: "bold", float: "left" }}
                          >
                            {thisList[0].fields.date_estimated}
                          </span>
                        ) : (
                          <input
                            name="date_estimated"
                            type="date"
                            style={input}
                            onChange={this.changeHandler}
                            defaultValue={thisList[0].fields.date_estimated}
                          ></input>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={cell}>Company Contact</td>
                      <td style={cell}>
                        {!this.state.editing ? (
                          <span
                            className="data-cell"
                            style={{ fontWeight: "bold", float: "left" }}
                          >
                            {thisList[0].fields.list_contact}
                          </span>
                        ) : (
                          <input
                            name="list_contact"
                            type="text"
                            style={input}
                            onChange={this.changeHandler}
                            defaultValue={thisList[0].fields.list_contact}
                          ></input>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={cell}>Contact Email</td>
                      <td style={cell}>
                        {!this.state.editing ? (
                          <span
                            className="data-cell"
                            style={{ fontWeight: "bold", float: "left" }}
                          >
                            {thisList[0].fields.contact_email}
                          </span>
                        ) : (
                          <input
                            name="contact_email"
                            type="text"
                            style={input}
                            onChange={this.changeHandler}
                            defaultValue={thisList[0].fields.contact_email}
                          ></input>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={cell}>Contact Phone</td>
                      <td style={cell}>
                        {!this.state.editing ? (
                          <span
                            className="data-cell"
                            style={{ fontWeight: "bold", float: "left" }}
                          >
                            {thisList[0].fields.contact_phone}
                          </span>
                        ) : (
                          <input
                            name="contact_phone"
                            type="text"
                            style={input}
                            onChange={this.changeHandler}
                            defaultValue={thisList[0].fields.contact_phone}
                          ></input>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {!this.state.editing ? (
                <button
                  onClick={this.editHandler.bind(this)}
                  className="action-link"
                >
                  [Edit]
                </button>
              ) : (
                <div>
                  <button
                    onClick={this.saveListHandler.bind(this)}
                    className="action-link"
                  >
                    [Save]
                  </button>
                  <button
                    onClick={this.editHandler.bind(this)}
                    className="action-link"
                  >
                    [Cancel]
                  </button>
                </div>
              )}
              {thisList.length > 0 && (
                <div>
                  <label htmlFor="public_notes" style={{ marginTop: "40px" }}>
                    Public Notes
                  </label>
                  <br />
                  <textarea
                    onChange={this.changeHandler}
                    name="public_notes"
                    id="public_notes"
                    cols="79"
                    rows="10"
                    defaultValue={thisList[0].fields.public_notes}
                  ></textarea>
                  <br />
                  <label htmlFor="private_notes" style={{ marginTop: "40px" }}>
                    Private Notes
                  </label>
                  <br />
                  <textarea
                    onChange={this.changeHandler}
                    name="private_notes"
                    id="private_notes"
                    cols="79"
                    rows="10"
                    defaultValue={thisList[0].fields.private_notes}
                  ></textarea>
                  <Button
                    style={button}
                    type="submit"
                    onClick={this.saveNotesHandler.bind(this)}
                  >
                    Save
                  </Button>
                </div>
              )}
              <button
                onClick={this.markCompleteHandler}
                className="action-link"
              >
                [Mark Complete]
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChecklistSummary
