/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from "react"
import { Link } from "gatsby"
import "./style.scss"

class SidebarListGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }
  componentDidMount() {
    let urlId =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search).get("pk")
        : ``
    if (parseInt(urlId) === this.props.list.pk)
      this.setState({ expanded: true })
  }

  toggleHandler() {
    let { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    let { list } = this.props
    let { expanded } = this.state
    let id =
      typeof window !== `undefined`
        ? parseInt(new URLSearchParams(window.location.search).get("pk"))
        : ``
    let isSummary =
      typeof window !== `undefined` &&
      window.location.pathname.includes("summary")
    let isTasks =
      typeof window !== `undefined` &&
      window.location.pathname.includes("tasks")

    return (
      <div className="sidebar-list-group-item">
        <li
          role="button"
          className="child toggler"
          onClick={this.toggleHandler.bind(this)}
          onKeyPress={this.toggleHandler.bind(this)}
        >
          {list.fields.title}
          <span className={`arrow ${expanded && `expanded`}`}>></span>
        </li>
        <li
          className={`child ${list.pk === id && isSummary ? "active" : ""}`}
          style={{ display: !expanded && `none` }}
        >
          <Link to={`/services/summary?pk=${list.pk}`}>Summary</Link>
        </li>
        <li
          className={`child ${list.pk === id && isTasks ? "active" : ""}`}
          style={{ display: !expanded && `none` }}
        >
          <Link to={`/services/tasks?pk=${list.pk}`}>Tasks</Link>
        </li>
      </div>
    )
  }
}

export default SidebarListGroup
