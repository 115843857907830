import React, { Component, createRef } from "react"
import "./style.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"
import Dropzone from "react-dropzone"
// import { cl } from "@services/consolelogger"

class TimecardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      errorMessage:
        "There was an error with your upload, please check your files and try again",
      downloadAvailable: false,
      sessionId: null,
      hasSecondFileInput: true,
      requiredFiles: [],
      selectedFiles: [],
      isSubmitted: false,
      allFilesReady: false,
      fileContent: ``,
      humanFilename: ``,
    }
    this.fileInput = createRef()
  }

  componentDidMount() {
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
    this.getClientTimecardConfig()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { session_id } = res.data
        this.setState({ session_id })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  // Get client configuration information on what to display
  getClientTimecardConfig() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/config`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { inputs } = res.data,
          { requiredFiles } = this.state
        inputs.map((input, i) => {
          requiredFiles.push(inputs[i])
          return null
        })
        this.setState({ requiredFiles })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  // Download a converted file
  downloadConverted(session_id, filename) {
    const convertStatus = {
      method: "POST",
      url: `${process.env.API_URL}/coastal/timecard/download`,
      responseType: 'arraybuffer',
      headers: {
        Accept: "application/*",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data: { session_id: session_id, filename: filename },
    }

    axios(convertStatus)
      .then((res) => {
        let { data } = res
        let contentType = res.headers["content-type"]
        let blob = new Blob([data], { type: contentType })
        let fileContent = URL.createObjectURL(blob)
        this.setState({ fileContent, downloadAvailable: true })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  // Get the status of a given conversion
  getConversionStatus(session_id) {
    //session_id="78377926-5eba-11eb-a383-37e539819a02"
    const convertStatus = {
      method: "POST",
      url: `${process.env.API_URL}/coastal/timecard/status`,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data: { session_id: session_id },
    }

    axios(convertStatus)
      .then((res) => {
        let { filename } = res.data
        let humanFilename = JSON.parse(filename)[0]
        this.setState({ humanFilename })
        this.downloadConverted(session_id, filename)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  // Function to do conversion
  startConversion(session_id, files) {
    let { requiredFiles, selectedFiles } = this.state
    let data = { session_id: session_id, type: "1" }
    requiredFiles.map((requiredFile, index) => {
      data[requiredFile.name] = selectedFiles[index].name
      return null
    })
    const convertOptions = {
      method: "POST",
      url: `${process.env.API_URL}/coastal/timecard/convert`,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data,
    }
    axios(convertOptions)
      .then((res) => {
        console.log("res")
        console.log(res)
        this.getConversionStatus(session_id)
      })
      .catch((err) => {
        let { data } = err.response,
          { errorMessage } = this.state
        if (data !== "") errorMessage = data
        this.setState({
          isSubmitted: false,
          error: true,
          errorMessage,
        })
      })
  }

  fileSelectedHandler = (ev, fileIndex) => {
    let { selectedFiles } = this.state
    selectedFiles[fileIndex] = ev[0]
    let error = false
    this.setState({ selectedFiles, error })
  }

  submitHandler = (ev) => {
    let { selectedFiles, session_id } = this.state
    ev.preventDefault()
    this.setState({ isSubmitted: true })
    let formData = new FormData()
    formData.append("metadata", '{"session_id": "' + session_id + '"}')
    selectedFiles.map((selectedFile) => {
      formData.append(selectedFile.name, selectedFile, selectedFile.name)
      return null
    })
    const uploadOptions = {
      method: "PUT",
      url: `${process.env.API_URL}/coastal/timecard/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data: formData,
    }
    axios(uploadOptions)
      .then((res) => {
        this.startConversion(this.state.session_id, this.state.selectedFiles)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  render() {
    let {
      error,
      errorMessage,
      requiredFiles,
      selectedFiles,
      downloadAvailable,
      isSubmitted,
      fileContent,
      humanFilename,
    } = this.state
    return (
      <>
        <h1>Timecard Conversion</h1>
        <form onSubmit={this.submitHandler.bind(this)}>
          {requiredFiles.map((requiredFile, index) => {
            return (
              <div className="file-drop-row" key={index}>
                <Dropzone
                  onDropAccepted={(files) =>
                    this.fileSelectedHandler(files, index)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p style={{ opacity: selectedFiles[index] ? 1 : 0.5 }}>
                        {selectedFiles[index]
                          ? selectedFiles[index].name
                          : requiredFile.description}
                      </p>
                      <Button id="filesubmit" name="filesubmit">
                        Choose
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </div>
            )
          })}
          {error && <p className="error-text">{errorMessage}</p>}
          <input
            type="submit"
            value="Convert"
            disabled={
              isSubmitted ||
              requiredFiles.length > selectedFiles.length ||
              selectedFiles.includes(undefined)
            }
          />
        </form>
        {downloadAvailable && (
          <form>
            <Button>
              <a href={fileContent} download={humanFilename}>
                Download Converted file
              </a>
            </Button>
          </form>
        )}
        <hr />
      </>
    )
  }
}

export default TimecardForm
