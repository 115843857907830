import React from "react"
import "./modal.css"
import Button from "react-bootstrap/Button"

export default class ChecklistItemModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "Y",
      title: "",
      date: "",
      desc: "",
    }
    this.updateType = this.updateType.bind(this)
    this.updateTitle = this.updateTitle.bind(this)
    this.updateDate = this.updateDate.bind(this)
    this.updateDesc = this.updateDesc.bind(this)
  }
  onClose = (e) => {
    this.props.hideModal(e)
  }
  submitTask = (ev) => {
    ev.preventDefault()
    let { title, date, desc, type } = this.state
    let data = { title, date, desc, type }
    this.props.AddTaskHandler(data, this.props.sessionId, this.props.pk)
  }
  updateType(e) {
    this.setState({ type: e.target.value })
  }
  updateTitle(e) {
    this.setState({ title: e.target.value })
  }
  updateDate(e) {
    this.setState({ date: e.target.value })
  }
  updateDesc(e) {
    this.setState({ desc: e.target.value })
  }
  htmlFormatModal(type) {
    const input = {
      width: "627px",
      height: "60px",
      padding: "19px",
      display: "inline-block",
    }
    const chooseButton = {
      marginLeft: "22px",
      width: "121px",
      height: "51px",
      display: "inline-block",
      fontSize: "17px",
      borderRadius: "20px",
    }
    switch (type) {
      case "F":
        return (
          <div>
            <label htmlFor="document" style={{ marginTop: "40px" }}>
              Document
            </label>
            <br />
            <input
              type="text"
              id="document"
              style={input}
              onSubmit={this.submitTask.bind(this)}
            ></input>
            <Button style={chooseButton}>Choose</Button>
            <br />
          </div>
        )
      case "Y":
        return null
      default:
        return null
    }
  }
  render() {
    const button = {
      margin: "22px",
      width: "121px",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const header = {
      borderBottom: "1px solid #35393B",
      paddingTop: "18px",
      paddingBottom: "18px",
      paddingLeft: "50px",
    }
    const dropdown = {
      width: "807px",
      height: "60px",
      padding: "19px",
    }
    const input = {
      width: "627px",
      height: "60px",
      padding: "19px",
      display: "inline-block",
    }
    if (!this.props.show) {
      return null
    }
    return (
      <div className="modal display-block">
        <section className="modal-main">
          <form
            onSubmit={(e) => {
              this.submitTask(e)
            }}
          >
            <div style={header}>
              New Task: <span style={{ fontWeight: "bold" }}>-----</span>
            </div>
            <div className="container" style={{ padding: "42px" }}>
              <label htmlFor="taskType">Task Type</label>
              <br />
              <select
                id="taskType"
                style={dropdown}
                onBlur={this.submitTask.bind(this)}
              >
                <option value="Y">Acknowledgement</option>
                <option value="F">Upload Request</option>
              </select>
              <br />
              <label htmlFor="title" style={{ marginTop: "40px" }}>
                Title
              </label>
              <br />
              <input
                type="text"
                id="title"
                style={input}
                onChange={this.updateTitle}
              ></input>
              <br />
              <label htmlFor="dueDate" style={{ marginTop: "40px" }}>
                Due Date
              </label>
              <br />
              <input
                type="date"
                id="listName"
                style={input}
                onSubmit={this.submitTask.bind(this)}
                onChange={this.updateDate}
              ></input>
              <br />
              <label htmlFor="shortDesc" style={{ marginTop: "40px" }}>
                Short Description
              </label>
              <br />
              <input
                type="text"
                id="shortDesc"
                style={input}
                onSubmit={this.submitTask.bind(this)}
                onChange={this.updateDesc}
              ></input>
              <br />
              <label htmlFor="public_notes" style={{ marginTop: "40px" }}>
                Public Notes
              </label>
              <br />
              <textarea
                onChange={this.changeHandler}
                name="public_notes"
                id="public_notes"
                cols="79"
                rows="10"
              />
              <br />
              <label htmlFor="private_notes" style={{ marginTop: "40px" }}>
                Private Notes
              </label>
              <br />
              <textarea
                onChange={this.changeHandler}
                name="private_notes"
                id="private_notes"
                cols="79"
                rows="10"
              />
              {this.htmlFormatModal(this.state.type)}
              <Button
                style={button}
                onClick={(e) => {
                  this.onClose(e)
                }}
              >
                Cancel
              </Button>
              <Button style={button} type="submit">
                Save List
              </Button>
            </div>
          </form>
        </section>
      </div>
    )
  }
}
