import React from "react"

// import Seo from "@components/seo"
import LoginForm from "@components/_molecules/form_login"

const Login = () => (
  <div>
    {/*<Seo title="Home" />*/}
    <main>
      <LoginForm />
    </main>
  </div>
)

export default Login
