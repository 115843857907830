import React from "react"
import { navigate } from "gatsby"
import Sidebar from "@components/_services/sidebar"
import "./services.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"
// import { cl } from "@services/consolelogger"

import ChecklistIndexRow from "@components/_molecules/form_checklist/_atoms/ChecklistIndexRow.js"

class TwoWay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
      session_id:
        typeof window !== `undefined` ? window.sessionStorage.bp_id : ``,
      lists: [],
    }
  }

  componentDidMount() {
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { session_id } = res.data
        this.setState({ session_id })
        this.getList()
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getList() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        this.setState({ lists: data })
        window.sessionStorage.setItem("lists", data)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  navNewList() {
    navigate("/services/newlist")
  }

  render() {
    const button = {
      // margin:'14px',
      width: "121px",
      height: "59px",
      float: "right",
      borderRadius: "20px",
    }
    const dropdown = {
      width: "627px",
      height: "60px",
      padding: "19px",
    }
    return (
      <div id="two-way" className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <>
            <Sidebar lists={this.state.lists} />
          </>
          <div style={{ width: `100%` }}>
            <h1>Checklist History</h1>
            <div className="container">
              <div className="checklist-controls">
                <select style={dropdown}>
                  <option>All Items</option>
                  <option>In Progress</option>
                  <option>Completed</option>
                </select>
                <Button style={button} onClick={this.navNewList}>
                  New List
                </Button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Estimated Date</th>
                    <th>List</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.lists.map(function (list, index) {
                    return <ChecklistIndexRow key={index} list={list} />
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default TwoWay
