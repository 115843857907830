import React, { Component } from "react"
import { navigate } from "gatsby"
import { logout } from "@services/auth"

class Logout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedOut: "false",
    }
  }

  componentDidMount() {
    logout(() => this.setState({ loggedOut: "true" }))
    setTimeout(() => {
      navigate(`/services/login`)
    }, 3000)
  }

  render() {
    return (
      <div
        style={{
          textAlign: `center`,
          color: `#555`,
        }}
      >
        <h3>Logout</h3>
        {!this.state.loggedOut ? (
          <p>Please wait while we log you out</p>
        ) : (
          <p>You are now logged out</p>
        )}
      </div>
    )
  }
}

export default Logout
