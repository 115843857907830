import React, { Component } from "react"
import "../style.scss"

class ChecklistLabel extends Component {
  getItems(listId) {
    console.log("Getting items for checklist ID " + listId)
  }

  render() {
    const data = this.props.checklist.fields
    const listId = this.props.checklist.pk
    const label = {
      borderTop: "solid #bcbcbc",
      borderTopWidth: "0.5px",
      padding: "5px",
    }
    const labelSegment = {
      display: "inline-block",
      width: "22%",
      marginRight: "0px",
    }
    return (
      <li key={listId}>
        <div style={label} key={listId}>
          <div style={labelSegment}>
            <span key={listId}>{data.title}</span>
            <br />
            <span>Admin</span>
          </div>
          <div style={labelSegment}>
            <span>{data.desc}</span>
            <br />
            <span>10/11 items completed</span>
          </div>
        </div>
      </li>
    )
  }
}

export default ChecklistLabel
