import React from "react"
import { navigate } from "gatsby"
import Sidebar from "@components/_services/sidebar"
import "./services.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"

class NewList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      session_id: ``,
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
      lists: [],
      templates: [],
      title: ``,
      deadline: ``,
      group: "Test API",
      desc: "",
      list_company: ``,
      list_contact: ``,
      contact_email: ``,
      contact_phone: ``,
      template_id: ``,
      public_notes: ``,
      private_notes: ``
    }
  }

  componentDidMount() {
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { session_id } = res.data
        this.setState({ session_id })
        this.getList()
        this.getTemplates()
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getList() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        this.setState({ lists: data })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getTemplates() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/default/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        data.unshift({ pk: "", fields: { title: "" } }) //adding blank option
        this.setState({ templates: data })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  changeHandler = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    })
  }

  submitHandler = (ev) => {
    ev.preventDefault()
    let {
      title,
      deadline,
      desc,
      group,
      list_company,
      list_contact,
      contact_email,
      contact_phone,
      template_id,
      public_notes,
      private_notes
    } = this.state
    const data = {
      list_title: title,
      list_est: deadline,
      list_desc: desc,
      group: group,
      list_company: list_company,
      list_contact: list_contact,
      contact_email: contact_email,
      contact_phone: contact_phone,
      template_id: template_id,
      public_notes: public_notes,
      private_notes: private_notes
    }
    
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
    }
    axios(options)
      .then((res) => {
        navigate("/services/two-way")
      })
      .catch((err) => {
        console.log(err)
        this.setState({ error: true })
      })
  }

  render() {
    const header = {
      borderTop: "1px solid #35393B",
      borderBottom: "1px solid #35393B",
      marginTop: "20px",
      paddingTop: "18px",
      paddingBottom: "18px",
      paddingLeft: "50px",
    }
    const input = {
      width: "730px",
      height: "60px",
      padding: "19px",
    }
    const button = {
      margin: "14px",
      width: "121px",
      height: "59px",
      float: "right",
      borderRadius: "20px",
    }
    this.options = [{ value: "6", label: "New Implementation" }]
    return (
      <div id="two-way" className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <>
            <Sidebar lists={this.state.lists} />
          </>
          <div style={{ width: `100%` }}>
            <div style={header}>New Checklist</div>
            <form
              onSubmit={this.submitHandler.bind(this)}
              className="container"
              style={{ padding: "75px" }}
            >
              <label htmlFor="listName">Checklist Name</label>
              <input
                onChange={this.changeHandler}
                name="title"
                type="text"
                id="listName"
                style={input}
              />
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                First Check Date
              </label>
              <input
                onChange={this.changeHandler}
                type="date"
                name="deadline"
                id="deadline"
                style={input}
              />
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                Description
              </label>
              <input
                onChange={this.changeHandler}
                type="text"
                name="desc"
                id="desc"
                style={input}
              />
              <label htmlFor="template_id" style={{ marginTop: "40px" }}>
                Template
              </label>
              <select
                onBlur={this.changeHandler}
                name="template_id"
                id="template_id"
                style={input}
              >
                {this.state.templates.map((template, i) => {
                  return (
                    <option key={i} value={template.pk}>
                      {template.fields.title}
                    </option>
                  )
                })}
              </select>
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                Company
              </label>
              <input
                onChange={this.changeHandler}
                type="text"
                name="list_company"
                id="list_company"
                style={input}
              />
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                Point of Contact
              </label>
              <input
                onChange={this.changeHandler}
                type="text"
                name="list_contact"
                id="list_contact"
                style={input}
              />
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                Point of Contact's Email
              </label>
              <input
                onChange={this.changeHandler}
                type="email"
                name="contact_email"
                id="contact_email"
                style={input}
              />
              <label htmlFor="deadline" style={{ marginTop: "40px" }}>
                Contact Phone Number
              </label>
              <input
                onChange={this.changeHandler}
                type="tel"
                name="contact_phone"
                id="contact_phone"
                style={input}
              />
              <br />
              <label htmlFor="public_notes" style={{ marginTop: "40px" }}>
                Public Notes
              </label>
              <br />
              <textarea
                onChange={this.changeHandler}
                name="public_notes"
                id="public_notes"
                cols="79"
                rows="10"
              />
              <br />
              <label htmlFor="private_notes" style={{ marginTop: "40px" }}>
                Private Notes
              </label>
              <br />
              <textarea
                onChange={this.changeHandler}
                name="private_notes"
                id="private_notes"
                cols="79"
                rows="10"
              />
              <Button
                style={button}
                onClick={() => {
                  navigate("/services/two-way")
                }}
              >
                Cancel
              </Button>
              <Button style={button} type="submit">
                Save List
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default NewList
