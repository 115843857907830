/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react"
import { Link } from "gatsby"
import SidebarListGroup from "@components/_molecules/sidebar_list_group"
import "./style.scss"

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: ``,
      timecard: false,
      twcl: false,
    }
  }

  componentDidMount() {
    let username =
      typeof window !== `undefined` ? window.sessionStorage.username : ``
    let timecard =
      typeof window !== `undefined`
        ? window.sessionStorage.getItem("service-Timecard") === "permitted"
        : false
    let twcl =
      typeof window !== `undefined`
        ? window.sessionStorage.getItem("service-TWCL") === "permitted"
        : false

    this.setState({ username, timecard, twcl })
  }

  render() {
    let { lists } = this.props
    let { username, timecard, twcl } = this.state
    return (
      <aside id="sidebar">
        <div className="sidebar-content">
          <ul>
            <li>{username}</li>
          </ul>
          {timecard && (
            <ul className="light">
              <li>
                <Link to="/services/timecard">Timecard Accruals</Link>
              </li>
            </ul>
          )}
          {twcl && (
            <ul className="light">
              <li role="button">
                <Link to="/services/two-way">Two Way Checklist</Link>
              </li>
              {lists &&
                lists.map((list, index) => (
                  <SidebarListGroup list={list} key={index} />
                ))}
            </ul>
          )}
        </div>
      </aside>
    )
  }
}

export default Sidebar
