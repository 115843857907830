import React from "react"
import "./modal.css"
import Button from "react-bootstrap/Button"

export default class ChecklistItemUserModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "",
    }
    this.updateType = this.updateType.bind(this)
  }
  onClose = e => {
    this.props.hideModal(e)
  }
  submitHandler = ev => {
    ev.preventDefault()
    let { title, deadline } = this.state
    console.log("Uploading list " + title + " with deadline " + deadline)
    if (title) {
    }
  }
  updateType(e) {
    this.setState({ type: e.target.value })
  }
  htmlFormatModal(type, doc) {
    const input = {
      width: "861px",
      height: "60px",
      padding: "19px",
      display: "inline-block",
    }
    const chooseButton = {
      marginLeft: "22px",
      width: "121px",
      height: "51px",
      display: "inline-block",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const button = {
      margin: "22px",
      width: "121px",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const link = {
      cursor: "pointer",
      color: "#059EEB",
    }
    switch (type) {
      case "C":
        return (
          <div>
            <a href="/" style={link}>
              {doc}
            </a>
            <Button
              style={button}
              onClick={e => {
                this.onClose(e)
              }}
            >
              Close
            </Button>
          </div>
        )
      default:
        return (
          <div>
            <input
              type="text"
              id="document"
              style={input}
              onSubmit={this.submitHandler.bind(this)}
            ></input>
            <Button style={chooseButton}>Choose</Button>
            <br />
            <Button
              style={button}
              onClick={e => {
                this.onClose(e)
              }}
            >
              Cancel
            </Button>
            <Button style={button}>Upload</Button>
          </div>
        )
    }
  }
  render() {
    const header = {
      height: "60px",
      padding: "19px",
      borderBottom: "1px solid #35393B",
    }
    const task = {
      height: "60px",
      borderBottom: "1px solid #35393B",
    }
    const label = {
      height: "45px",
      marginTop: "25px",
    }
    const link = {
      cursor: "pointer",
      color: "#059EEB",
      marginTop: "25px",
    }
    if (!this.props.existing) {
      return null
    }
    const data = this.props.modalItem.fields
    return (
      <div className="modal display-block">
        <section className="modal-main">
          <div style={header}>
            Task: <span style={{ fontWeight: "bold" }}>{data.title}</span>
          </div>
          <div className="container" style={{ padding: "42px" }}>
            <div style={task}>Due: {data.date_due}</div>
            <div style={label}>Task Description</div>
            <div style={task}>{data.desc}</div>
            {data.doc ||
              (data.status !== "C" && <div style={label}>Document</div>)}
            {this.htmlFormatModal(data.status, data.doc_loc)}
            <a href="/" style={link}>
              [Mark Complete]
            </a>
          </div>
        </section>
      </div>
    )
  }
}
