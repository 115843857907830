import React, { Component } from "react"
import UpdateTaskDetailModal from "@components/_molecules/modal_checklist_task_update"
import "./style.scss"
/* allow react/jsx-no-duplicate-props */

class TasksTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      title: ``,
      description: ``,
      date_due: ``,
      pk: ``,
      item_status: ``,
      file_ids: ``,
      item_type: ``,
      public_notes: ``,
      private_notes: ``,
      session_id: this.props.sessionId,
      taskListName: this.props.taskListName,
      initialLoadComplete: this.props.initialLoadComplete,
    }
    this.showModalHandler = this.showModalHandler.bind(this)
    this.closeModalHandler = this.closeModalHandler.bind(this)
  }

  showModalHandler(ev) {
    ev.preventDefault()
    let title = ev.target.getAttribute("title"),
      description = ev.target.getAttribute("description"),
      date_due = ev.target.getAttribute("date_due"),
      pk = ev.target.getAttribute("pk"),
      item_status = ev.target.getAttribute("item_status"),
      fileString = ev.target.getAttribute("file_ids"),
      item_type = ev.target.getAttribute("item_type"),
      public_notes = ev.target.getAttribute("public_notes"),
      private_notes = ev.target.getAttribute("private_notes")
    let file_ids = fileString !== "" ? fileString.split(",") : []
    this.setState({
      title,
      description,
      date_due,
      pk,
      item_status,
      file_ids,
      item_type,
      public_notes,
      private_notes,
    })
    this.modalOn()
  }

  modalOn = () => {
    this.setState({ showModal: true })
  }

  closeModalHandler(ev) {
    ev.preventDefault()
    this.setState({ showModal: false })
  }

  render() {
    let { data, initialLoadComplete } = this.props
    let status = { C: "Complete", O: "Overdue", U: "Upcoming" }
    return (
      <div>
        <table className="task-table">
          <thead>
            <tr>
              <th>Due</th>
              <th>Task</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((row, index) => (
                <tr key={index}>
                  <td className="task-due-date">{row.fields.date_due}</td>
                  <td>
                    <a
                      onClick={this.showModalHandler.bind(this)}
                      title={row.fields.title}
                      description={row.fields.desc}
                      file_ids={row.fields.item_files}
                      pk={row.pk}
                      item_status={row.fields.status}
                      item_type={row.fields.item_type}
                      date_due={row.fields.date_due}
                      public_notes={row.fields.public_notes}
                      private_notes={row.fields.private_notes}
                      href="./"
                    >
                      {row.fields.desc}
                    </a>
                  </td>
                  <td>{status[row.fields.status]}</td>
                </tr>
              ))
            ) : (
              <tr className="empty-list">
                {initialLoadComplete ? (
                  <td colSpan="3">No Items in this list</td>
                ) : (
                  <td colSpan="3">Loading</td>
                )}
              </tr>
            )}
          </tbody>
        </table>
        {this.state.showModal && (
          <UpdateTaskDetailModal
            show={this.state.showModal}
            hideModal={this.closeModalHandler}
            title={this.state.title}
            description={this.state.description}
            date_due={this.state.date_due}
            pk={this.state.pk}
            item_status={this.state.item_status}
            file_ids={this.state.file_ids}
            item_type={this.state.item_type}
            session_id={this.props.sessionId}
            public_notes={this.state.public_notes}
            private_notes={this.state.private_notes}
            taskListName={this.props.taskListName}
          />
        )}
      </div>
    )
  }
}

export default TasksTable
