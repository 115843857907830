import React, { Component } from "react"
import Sidebar from "@components/_services/sidebar"
import axios from "axios"
import TasksTable from "@components/_molecules/table_tasks"
import TaskDetailModal from "@components/_molecules/modal_checklist_task"
import Button from "react-bootstrap/Button"

class ChecklistTasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      session_id: ``,
      pk: ``,
      data: [],
      overdue: [],
      upcoming: [],
      complete: [],
      taskListName: "",
      thisList: [],
      lists: [],
      showModal: false,
      isNew: false,
      initialLoadComplete: false,
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
    }
    this.modalClose = this.modalClose.bind(this)
  }

  componentDidMount() {
    let urlParams =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : ``
    this.setState({ pk: urlParams.get("pk") })
    // Generate a new session for each time we regenerate the constructor
    this.getNewSession()
  }

  componentWillReceiveProps(nextProps) {
    // const { pk } = nextProps.match.params
    // this.setState({ pk })
    this.getTasks()
    this.getList()
  }

  getNewSession() {
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/coastal/timecard/session`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { session_id } = res.data
        this.setState({ session_id })
        this.getList()
        this.getTasks()
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getList() {
    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let urlParams =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : ``
    let pk = urlParams.get("pk")
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        let thisList = data.filter((list) => list.pk.toString() === pk)
        let taskListName = data.length ? thisList[0].fields.title : ``
        this.setState({ lists: data, taskListName, thisList })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  getTasks() {
    let { session_id } = this.state
    let urlParams =
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : ``
    let pk = urlParams.get("pk")
    let data = { session_id, list_id: pk }
    let options = {
      method: "GET",
      url: `${process.env.API_URL}/twcl/items`,
      params: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        var overdue = [],
          upcoming = [],
          complete = []
        data.map((row) => {
          if (row.fields.status === "O") overdue.push(row)
          if (row.fields.status === "U") upcoming.push(row)
          if (row.fields.status === "C") complete.push(row)
          return null
        })
        this.setState({
          data,
          overdue,
          upcoming,
          complete,
          initialLoadComplete: true,
        })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  modalOpen(ev, data = {}) {
    ev.preventDefault()
    let isNew = ev.target.getAttribute("data-action") === "new"
    this.setState({ isNew, showModal: true })
  }

  modalClose(ev) {
    ev.preventDefault()
    this.setState({ showModal: false })
  }

  createTask(formData, session_id, list_id) {
    let data = {
      session_id,
      list_id,
      item_desc: formData.desc,
      item_type: formData.type,
      item_due: formData.date,
    }
    let options = {
      method: "POST",
      url: `${process.env.API_URL}/twcl/items`,
      params: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    let newRow = {
      fields: {
        date_due: formData.date,
        desc: formData.desc,
        item_type: formData.type,
        status: "U",
      },
    }
    axios(options)
      .then((res) => {
        let { upcoming } = this.state
        upcoming.push(newRow)
        this.setState({ upcoming, showModal: false })
      })
      .catch((err) => {})
  }

  render() {
    let {
      overdue,
      upcoming,
      complete,
      lists,
      session_id,
      initialLoadComplete,
      thisList,
    } = this.state
    return (
      <div className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <Sidebar lists={this.state.lists} />
          <div className="table-container-wrap">
            <div className="list-header">
              Tasks - {lists.length && thisList[0].fields.title}
            </div>
            <div className="table-container">
              <div className="table-header-row">
                <h2>Overdue</h2>
                <Button data-action="new" onClick={this.modalOpen.bind(this)}>
                  New Item
                </Button>
              </div>
              <div style={{ width: `100%` }}>
                <TasksTable
                  data={overdue}
                  sessionId={this.state.session_id}
                  taskListName={this.state.taskListName}
                  initialLoadComplete={initialLoadComplete}
                />
              </div>
            </div>
            <div className="table-container">
              <div className="table-header-row">
                <h2>Upcoming</h2>
              </div>
              <div style={{ width: `100%` }}>
                <TasksTable
                  data={upcoming}
                  sessionId={this.state.session_id}
                  taskListName={this.state.taskListName}
                  initialLoadComplete={initialLoadComplete}
                />
              </div>
            </div>
            <div className="table-container">
              <div className="table-header-row">
                <h2>Complete</h2>
              </div>
              <div style={{ width: `100%` }}>
                <TasksTable
                  data={complete}
                  sessionId={this.state.session_id}
                  taskListName={this.state.taskListName}
                  initialLoadComplete={initialLoadComplete}
                />
              </div>
            </div>
          </div>
        </div>
        {session_id && (
          <TaskDetailModal
            isNew={this.state.isNew}
            show={this.state.showModal}
            listId={this.state.pk}
            hideModal={this.modalClose}
            AddTaskHandler={this.createTask.bind(this)}
            sessionId={this.state.session_id}
            pk={this.state.pk}
            parentState={this.state}
            taskListName={this.state.taskListName}
          />
        )}
      </div>
    )
  }
}

export default ChecklistTasks
