import React, { Component } from "react"
import "./style.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"

import ChecklistLabel from "./_atoms/index.js"
import ChecklistItemRow from "./_atoms/ChecklistItemRow.js"
import ChecklistItemModal from "./_atoms/ChecklistItemModal.js"
import ChecklistItemUserModal from "./_atoms/ChecklistItemUserModal.js"

class ChecklistForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      sessionId: null,
      items: [],
      listId: "",
      show: false,
      existing: false,
      overdue: false,
      upcoming: false,
      complete: false,
      modalItem: null,
      client_id:
        typeof window !== `undefined` ? window.sessionStorage.username : ``,
      session_id:
        typeof window !== `undefined` ? window.sessionStorage.bp_id : ``,
      lists: [],
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showExisting = this.showExisting.bind(this)
    this.hideExisting = this.hideExisting.bind(this)
  }

  showModal = (e) => {
    this.setState({ show: true })
  }

  showExisting = (e, item) => {
    this.setState({ existing: true, modalItem: item })
  }

  hideModal = (e) => {
    this.setState({ show: false })
  }

  hideExisting = (e) => {
    this.setState({ existing: false })
  }

  updateCategories = (item) => {
    if (item.fields.status === "U" && !this.state.upcoming) {
      this.setState({ upcoming: true })
    }
    if (item.fields.status === "O" && !this.state.overdue) {
      this.setState({ overdue: true })
    }
    if (item.fields.status === "C" && !this.state.complete) {
      this.setState({ complete: true })
    }
  }

  componentDidMount() {
    const checklistParams = {
      method: "get",
      url: `${process.env.API_URL}/twcl/items`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      params: {
        session_id: "session",
        list_id: window.location.href.substr(window.location.href.length - 1),
      },
    }
    axios(checklistParams)
      .then((res) => {
        console.log(res)
        this.setState({
          items: res["data"],
        })
      })
      .catch((err) => {
        console.log(err)
      })

    let { client_id, session_id } = this.state
    let data = { client_id, session_id }
    let options = {
      method: "GET",
      params: data,
      url: `${process.env.API_URL}/twcl/list`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
    }
    axios(options)
      .then((res) => {
        let { data } = res
        console.log(data)
        this.setState({ lists: data })
        window.sessionStorage.setItem("lists", data)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  createChecklist(checklist) {
    return <ChecklistLabel checklist={checklist} />
  }

  render() {
    const header = {
      borderTop: "1px solid #35393B",
      borderBottom: "1px solid #35393B",
      marginTop: "20px",
      paddingTop: "18px",
      paddingBottom: "18px",
      paddingLeft: "50px",
    }
    const button = {
      width: "121px",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const container = {
      marginBottom: "100px",
    }
    const containerHeader = {
      marginLeft: "20px",
      height: "64px",
      fontSize: "20px",
    }
    this.state.items.map((item) => {
      this.updateCategories(item)
      return null
    })
    return (
      <div id="two-way" className="container" style={{ padding: 0 }}>
        <div style={{ display: `flex` }}>
          <div style={{ width: `100%` }}>
            <div style={header}>Tasks</div>
            <div className="container" style={{ padding: "50px" }}>
              <ChecklistItemModal
                show={this.state.show}
                hideModal={this.hideModal}
                listId={"1"}
              >
                Message in Modal
              </ChecklistItemModal>
              <ChecklistItemUserModal
                existing={this.state.existing}
                hideModal={this.hideExisting}
                modalItem={this.state.modalItem}
              >
                Message in Modal
              </ChecklistItemUserModal>
              <Button
                style={button}
                onClick={(e) => {
                  this.showModal(e)
                }}
              >
                New Item
              </Button>
              {this.state.overdue && (
                <div style={container}>
                  <div style={containerHeader}>Overdue</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Due</th>
                        <th>Task</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map(function (item) {
                        if (item.fields.status === "O") {
                          return (
                            <ChecklistItemRow
                              fields={item}
                              showExisting={this.showExisting}
                            />
                          )
                        }
                        return null
                      }, this)}
                    </tbody>
                  </table>
                </div>
              )}
              {this.state.upcoming && (
                <div style={container}>
                  <div style={containerHeader}>Upcoming</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Due</th>
                        <th>Task</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map(function (item) {
                        if (item.fields.status === "U") {
                          return (
                            <ChecklistItemRow
                              fields={item}
                              showExisting={this.showExisting}
                            />
                          )
                        }
                        return null
                      }, this)}
                    </tbody>
                  </table>
                </div>
              )}
              {this.state.complete && (
                <div style={container}>
                  <div style={containerHeader}>Complete</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Due</th>
                        <th>Task</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map(function (item) {
                        if (item.fields.status === "C") {
                          return (
                            <ChecklistItemRow
                              fields={item}
                              showExisting={this.showExisting}
                            />
                          )
                        }
                        return null
                      }, this)}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChecklistForm
