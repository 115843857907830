import React, { Component } from "react"
import { navigate } from "gatsby"
import Button from "react-bootstrap/Button"
import axios from "axios"
import Dropzone from "react-dropzone"

class TaskDetailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "Y",
      title: "",
      date: "",
      desc: "",
      taskListName: this.props.taskListName,
      requiredFiles: [],
      selectedFiles: [],
      availableDocs: [],
      session_id: this.props.sessionId,
      pk: this.props.pk,
    }
  }

  onClose = (e) => {
    this.props.hideModal(e)
  }
  submitTask = (ev) => {
    ev.preventDefault()
    let { type, title, date, desc, selectedFiles, session_id } = this.state
    console.log(this.props)
    let list = this.props.listId
    if (title) {
      const data = {
        list_id: list,
        item_type: type,
        item_title: title,
        item_due: date,
        item_desc: desc,
        session_id: session_id,
      }
      let formData = new FormData()
      formData.append("metadata", JSON.stringify(data))
      selectedFiles.map((selectedFile) => {
        formData.append(selectedFile.name, selectedFile, selectedFile.name)
        return null
      })

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.bp_id}`,
          // "Access-Control-Allow-Origin": "http://127.0.0.1:9210/",
          // "Access-Control-Allow-Credentials": "true",
        },
        data: formData,
        url: `${process.env.API_URL}/twcl/items`,
      }
      axios(options)
        .then((res) => {
          console.log("Navigating back to checklist")
          navigate(`/services/tasks?pk=${this.props.listId}`)
        })
        .catch((err) => {
          console.log(err)
          this.setState({ error: true })
        })
    }
  }
  updateTask = (ev) => {
    ev.preventDefault()
    let { type, title, date, desc } = this.state
    let list = this.props.listId
    if (title) {
      const data = {
        list_id: list,
        item_type: type,
        item_title: title,
        item_due: date,
        item_desc: desc,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.bp_id}`,
          // "Access-Control-Allow-Origin": "http://127.0.0.1:9210/",
          // "Access-Control-Allow-Credentials": "true",
        },
        params: data,
        url: `${process.env.API_URL}/twcl/items`,
      }
      axios(options)
        .then((res) => {
          console.log("Navigating back to checklist")
          navigate(`/services/tasks?pk=${this.props.listId}`)
        })
        .catch((err) => {
          console.log(err)
          this.setState({ error: true })
        })
    }
  }

  changeHandler = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    })
  }

  //not using because we will do all this in submithandler
  uploadFile = (ev) => {
    ev.preventDefault()
    let { selectedFiles, session_id, pk } = this.state
    let formData = new FormData()
    formData.append(`session_id`, session_id)
    formData.append(`item_id`, pk)
    selectedFiles.map((selectedFile) => {
      formData.append(selectedFile.name, selectedFile, selectedFile.name)
      return null
    })
    const uploadOptions = {
      method: "PUT",
      url: `${process.env.API_URL}/twcl/files`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      data: formData,
    }
    axios(uploadOptions)
      .then((res) => {
        this.startConversion(this.state.session_id, this.state.selectedFiles)
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  fileSelectedHandler = (ev) => {
    let { selectedFiles } = this.state
    selectedFiles[0] = ev[0]
    let error = false
    this.setState({ selectedFiles, error })
  }

  getDocs = () => {
    let { session_id, pk } = this.state
    let data = { session_id, item_id: pk }
    const uploadOptions = {
      method: "GET",
      url: `${process.env.API_URL}/twcl/files`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.bp_id}`,
      },
      params: data,
    }
    axios(uploadOptions)
      .then((res) => {
        console.log(res.data)
        this.setState({ availableDocs: res.data })
      })
      .catch((err) => {
        this.setState({ isSubmitted: false, error: true })
      })
  }

  render() {
    const button = {
      margin: "22px",
      width: "121px",
      height: "51px",
      float: "right",
      fontSize: "17px",
      borderRadius: "20px",
    }
    const header = {
      borderBottom: "1px solid #35393B",
      paddingTop: "18px",
      paddingBottom: "18px",
      paddingLeft: "50px",
    }
    const dropdown = {
      width: "807px",
      height: "60px",
      padding: "19px",
    }
    const input = {
      width: "627px",
      height: "60px",
      padding: "19px",
      display: "inline-block",
    }
    if (!this.props.show) {
      return null
    }
    let { title, desc, date } = this.state
    let { selectedFiles } = this.state
    let showUpload = false //selectedFiles.length > 0

    return (
      <div className="modal display-block">
        <section className="modal-main">
          <div style={header}>
            {this.props.isNew ? `New Task` : `Edit Task`}:{" "}
            <span style={{ fontWeight: "bold" }}>
              {this.props.taskListName}
            </span>
          </div>
          <div className="container" style={{ padding: "42px" }}>
            <label htmlFor="taskType">Task Type</label>
            <br />
            <select
              id="taskType"
              style={dropdown}
              name="type"
              onChange={this.changeHandler.bind(this)}
              onBlur={this.submitTask.bind(this)}
            >
              <option value="Y">Acknowledgement</option>
              <option value="F">Upload Request</option>
            </select>
            <br />
            <label htmlFor="title" style={{ marginTop: "40px" }}>
              Title
            </label>
            <br />
            <input
              type="text"
              id="title"
              style={input}
              name="title"
              onChange={this.changeHandler.bind(this)}
            />
            <br />
            <label htmlFor="dueDate" style={{ marginTop: "40px" }}>
              Due Date
            </label>
            <br />
            <input
              type="date"
              id="listName"
              name="date"
              style={input}
              onSubmit={this.submitTask.bind(this)}
              onChange={this.changeHandler.bind(this)}
            />
            <br />
            <label htmlFor="shortDesc" style={{ marginTop: "40px" }}>
              Short Description
            </label>
            <br />
            <input
              type="text"
              id="shortDesc"
              name="desc"
              style={input}
              onChange={this.changeHandler.bind(this)}
            />
            <br />
            {this.state.type === "F" && (
              <div className="file-drop-row">
                <label
                  htmlFor="document"
                  style={{ marginTop: "40px", width: `100%` }}
                >
                  Document
                </label>
                <Dropzone
                  onDropAccepted={(files) => this.fileSelectedHandler(files)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="dropzone"
                      {...getRootProps()}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <input {...getInputProps()} />
                      <p style={{ opacity: selectedFiles[0] ? 1 : 0.5 }}>
                        {selectedFiles[0]
                          ? selectedFiles[0].name
                          : `Drop file here`}
                      </p>
                      <Button
                        id="filesubmit"
                        name="filesubmit"
                        style={{ width: `120px` }}
                      >
                        Choose
                      </Button>
                    </div>
                  )}
                </Dropzone>
                {showUpload && (
                  <Button
                    onClick={this.uploadFile.bind(this)}
                    style={{ width: `120px` }}
                  >
                    Upload
                  </Button>
                )}
              </div>
            )}
            <br />
            <Button
              style={button}
              onClick={(e) => {
                this.onClose(e)
              }}
            >
              Cancel
            </Button>
            {this.props.isNew ? (
              <Button
                style={button}
                disabled={!(!!title && !!date && !!desc)}
                onClick={(e) => {
                  this.submitTask(e)
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                style={button}
                onClick={(e) => {
                  this.updateTask(e)
                }}
              >
                Update
              </Button>
            )}
          </div>
        </section>
      </div>
    )
  }
}

export default TaskDetailModal
